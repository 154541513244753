import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  getVerifyImage: async (mobile: string) => {
    return await http.get(`${getApiUrl()}/api/verify/${mobile}`);
  },
  sendVerifyCode: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/verify/check`, { data });
  },
  realname: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/verify/realname`, {
      data,
    });
  },
};

export default {
  ...actions,
};
